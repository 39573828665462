import { CustomDetailCellRendererProps } from "ag-grid-react";
import { defaultAutoCompleteProperties, defaultDropDownProperties, defaultMultiLineProperties, defaultRadioProperties, defaultStringProperties, FormState, IPropertyType } from "./definitions";

interface IDataLayoutItem {
    id: number;
    dataLayoutName: string;
    formatTypeId: number | null;
    tableTemplateId: number | null;
    commentCharacter: string;
    textQualifier: string;
    columnDelimiter: string; //1 Column Delimiter
    xmlRowTag: string; //300 XML Row Tag
    dataAddress: string; //100 Data Address
    summaryDataRowCount: number | null;
    dataRowRecordType: string; //3 Data Row Record Type
    dataRowColumnWidths: string; //300 Data Row Column Widths
    headerRowRecordType: string; //1 Header Row Record Type
    headerRowColumnCounts: string; //300 Header Row Column Counts
    trailerRowRecordType: string; //1 Trailer Row Record Type
    trailerRowColumnWidths: string; //300 Trailer Row Column Counts
    siblingDataRowRecordType: string;
    hasColumnHeader: boolean | null;
    hasTrailer: boolean | null;
    hasFileInfo: boolean | null;
    createdBy: string | null;
    createdDate: string | null;
    notes: string;
    isMapped: boolean;

    dataLayoutFields: IDataLayoutFieldItem[] | null;
    dataLayoutFieldMappings: IDataLayoutFieldMappingItem[] | null;
}

interface IDataLayoutFieldItem {
    id: number;
    dataLayoutId: number;
    recordType: string;
    fieldName: string;
    fieldTypeId: number;
    expectation: string;
    expectationAction: string;
    fieldIndexNumber: number | null;
    maximumLength: number | null;
    startingPosition: number | null;
    pci: boolean | null;
    pii: boolean | null;
    fingerPrint: boolean | null;
    cardIdentifier: boolean | null;
    accountIdentifier: boolean | null;
    rowNumber: number | null;
    notes: string;
    fieldIndicator: string;
    createdBy: string | null;
    createdDate: string | null;
    index?: number | null;

    bronzeFileType: IBronzeFieldTypeItem | null;
}

export const dataLayoutResponsiveProps = {
    xsFormControl: 6,
    mdFormControl: 6,
}

export const dataLayoutStringProperties: IPropertyType = {
    ...defaultStringProperties,
    ...dataLayoutResponsiveProps,
}

export const dataLayoutAutoCompleteProperties: IPropertyType = {
    ...defaultAutoCompleteProperties,
    ...dataLayoutResponsiveProps,
}

export const dataLayoutDropDownProperties: IPropertyType = {
    ...defaultDropDownProperties,
    ...dataLayoutResponsiveProps,
}

export const dataLayoutMultiLineProperties: IPropertyType = {
    ...defaultMultiLineProperties,
    ...dataLayoutResponsiveProps,
}

export const dataLayoutRadioProperties: IPropertyType = {
    ...defaultRadioProperties,
    ...dataLayoutResponsiveProps,
}

interface IDataLayoutFieldMappingItem {
    id: number;
    dataLayoutId: number;
    targetFieldId: number | null;
    sourceTableTemplateId: number | null;
    sourceFieldName: string;
    expectation: string;
    expectationAction: string;
    defaultValue: string;
    notes: string;
    createdBy: string | null;
    createdDate: string | null;
    index: number | null;
    customTransformation: string;

    sourceTableTemplate: ITableTemplateItem | null;
    targetField: ISilverFieldItem | null;
}

interface ITableTemplateItem {
    id: number;
    description: string;
    tableName: string;
    version: string;
    bronzeStorageLocation: string;
    silverStorageLocation: string;
    domainName: string;
    lookupTable: boolean | null;
    createdBy: string | null;
    createdDate: string | null;
}

interface IDataLayoutFormatTypeItem {
    id: number;
    formatType: string;
    createdBy: string | null;
    createdDate: string | null;
}

interface IBronzeFieldTypeItem {
    id: number;
    fieldType: string;
    label: string;
    createdBy: string | null;
    createdDate: string | null;
}

interface INewSilverFieldItem {
    tableTemplateId?: number | null;
    fieldName?: string;
    fieldTypeId?: number | null;
    fieldFormat?: string;
    generateFromFingerprint?: boolean | null;
    primaryKey?: boolean | null;
    transactionIdentifier?: boolean | null;
    statusIdentifier?: boolean | null;
    storeNumberIdentifier?: boolean | null;
    defaultExpectation?: string;
    defaultExpectationAction?: string;
}

interface ISilverFieldItem {
    id: number;
    tableTemplateId: number | null;
    fieldName: string;
    fieldTypeId: number | null;
    fieldFormat: string;
    generateFromFingerprint: boolean | null;
    primaryKey: boolean | null;
    transactionIdentifier: boolean | null;
    statusIdentifier: boolean | null;
    storeNumberIdentifier: boolean | null;
    defaultExpectation: string;
    defaultExpectationAction: string;
    createdBy: string | null;
    createdDate: string | null;
    fieldDisplayName: string;

    bronzeFieldType: IBronzeFieldTypeItem | null;
    tableTemplate: ITableTemplateItem | null;
}

type NewDraftDataLayoutFormState = FormState & {
    id?: number;
};

interface INewDraftDataLayoutItem {
    dataLayoutName: string;
}

interface DataLayoutFieldCellRendererProps extends CustomDetailCellRendererProps {
    listFieldTypes: IBronzeFieldTypeItem[];
    isReadOnly: boolean;
    handleUpdateDiff: Function;
    isDiff?: boolean;
    handleDelete: Function;
}

interface DataLayoutFieldMappingCellRendererProps extends CustomDetailCellRendererProps {
    listSilverFields: ISilverFieldItem[];
    listTableTemplates: ITableTemplateItem[];
    listDataLayoutFields: IDataLayoutFieldItem[];
    isReadOnly: boolean;
    handleUpdateDiff: Function;
    isDiff?: boolean;
    handleDelete: Function;
}


export type {
    IDataLayoutItem,
    IDataLayoutFormatTypeItem,
    NewDraftDataLayoutFormState,
    INewDraftDataLayoutItem,
    DataLayoutFieldCellRendererProps,
    DataLayoutFieldMappingCellRendererProps,
    ITableTemplateItem,
    ISilverFieldItem,
    IBronzeFieldTypeItem,
    IDataLayoutFieldItem,
    IDataLayoutFieldMappingItem,
    INewSilverFieldItem,
}